<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('user.dashboard_user') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  id="form" @submit.prevent="handleSubmit(userSave)" @reset.prevent="reset">
            <b-row>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Org Id" vid="org_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="org_id"
                    slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                      {{ $t('user_role.organization')}} <span class="text-danger">*</span>
                      </template>
                    <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="orgList"
                      id="org_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Office Type id" vid="office_type_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="office_type_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('user.office_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.office_type_id"
                      :options="officeTypeList"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Office Id" vid="office_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="office_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('user.office')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.office_id"
                      :options="officeList"
                      id="office_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Designation Id" vid="designation_id">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="designation_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('user_role.designation')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.designation_id"
                      :options="designationList"
                      id="designation_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-overlay :show="loadingData">
              <b-row>
                <div class="col-md-12 mt-3">
                  <fieldset class="p-2 w-100">
                    <legend class="px-2 w-50 shadow-sm">{{$t('user.dashboard_user')}} {{$t('globalTrans.list')}}</legend>
                      <table class="table" style="width:100%">
                        <thead class="thead">
                          <tr>
                            <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
                            <th>{{$t('globalTrans.name')}}</th>
                            <th>{{$t('globalTrans.mobile')}}</th>
                            <th>{{$t('globalTrans.designation')}}</th>
                            <th>{{$t('globalTrans.select')}}</th>
                          </tr>
                        </thead>
                        <tr v-for="(detail,index) in search.details" :key="index">
                          <td class="text-center">{{$n(index+1)}}</td>
                          <td>
                            {{ ($i18n.locale==='bn') ? detail.text_bn : detail.text_en }}
                          </td>
                          <td>
                            {{ detail.mobile }}
                          </td>
                          <td>
                            {{ ($i18n.locale==='bn') ? detail.designation_name_bn : detail.designation_name }}
                          </td>
                          <td>
                            <b-form-checkbox
                              :id="'checkbox-' + detail.id"
                              v-model="detail.dashboard_user"
                              :name="'checkbox-' + detail.id"
                              value=1
                              unchecked-value=0
                            >
                            </b-form-checkbox>
                          </td>
                          <td>
                          </td>
                        </tr>
                        <template v-if="search.details.length === 0">
                          <tr>
                            <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                          </tr>
                        </template>
                      </table>
                  </fieldset>
                </div>
              </b-row>
            </b-overlay>
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col text-right">
                <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
              </div>
            </div>
           </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import RestApi, { authServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { officeDesignationsApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  data () {
    return {
      showData: false,
      search: {
        org_id: 0,
        office_id: 0,
        office_type_id: 0,
        designation_id: 0,
        details: []
      },
      testId: 0,
      rows: [],
      officeTypeList: [],
      officeList: [],
      designationList: [],
      loadingData: false,
      officeDesignations: [],
      users: [],
      user_detail: '',
      compData: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.orgList.filter(item => item.status === 0)
    },
    ...mapGetters({
      commonObj: 'commonObj',
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'search.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getParentOfficeList(newVal)
      }
    },
    'search.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setOfficeWiseDesignation(newVal)
        this.userData()
      }
    },
    'search.designation_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.userData()
      }
    },
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.userData()
      }
    }
  },
  created () {
  },
  mounted () {
    this.getOfficeDesignations()
  },
  methods: {
    getOfficeDesignations () {
      RestApi.getData(commonServiceBaseUrl, officeDesignationsApi, {}).then(response => {
        this.officeDesignations = response.data
      })
    },
    setOfficeWiseDesignation (officeId) {
      if (this.officeDesignations.length === 0) {
        this.designationList = []
        return
      }
      const filteredOfficeDesignations = []
      const designationList = this.$store.state.commonObj.designationList
      this.officeDesignations.forEach(element => {
        if (element.office_id === officeId) {
          const tmp = designationList.find(item => item.value === element.designation_id)
          filteredOfficeDesignations.push(tmp)
        }
      })
      this.designationList = filteredOfficeDesignations
    },
    getDesignationList (orgId = null) {
      const designationList = this.$store.state.commonObj.designationList
      let listObject = []
      if (orgId) {
        listObject = designationList.filter(designation => designation.org_id === orgId)
      } else {
        listObject = designationList
      }
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList
      let listObject = []
      if (orgId) {
        listObject = officeTypeList.filter(officeType => officeType.org_id === orgId)
      } else {
        listObject = officeTypeList
      }
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    getParentOfficeList (officeTypeId = null) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
          return officeList.filter(office => office.office_type_id === officeTypeId)
      }
      return officeList
    },
    details (item) {
      this.user_detail = item
    },
    async userData () {
      this.loadingData = true
      const search = this.search
      search.details = []
      await RestApi.getData(authServiceBaseUrl, 'user/user-list', search).then(response => {
        if (response.success) {
          const listData = response.data.map(item => {
            const designationObj = this.$store.state.commonObj.designationList.find(doc => doc.value === parseInt(item.designation_id))
            const designationData = {}
            if (typeof designationObj !== 'undefined') {
              designationData.designation_name = designationObj.text_en
              designationData.designation_name_bn = designationObj.text_bn
            } else {
              designationData.designation_name = ''
              designationData.designation_name_bn = ''
            }
            return Object.assign({}, item, designationData)
          })
          this.search.details = listData
          this.loadingData = false
        }
      }, error => {
        this.loadingData = false
        if (error) {
         //
        }
      })
    },
    async userSave () {
      this.loadingData = true
      const search = this.search
      await RestApi.postData(authServiceBaseUrl, 'user/dashboard-user', search).then(response => {
        // this.userData()
         this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.loadingData = false
      }, error => {
        if (error) {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        this.loadingData = false
      })
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  }
}
</script>
